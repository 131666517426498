import "./FooterStyles.css"

const Footer = () =>{
	return (
    <div className="footer">
      <div className="top">
        <div>
          <h1 className="footer-logo">Slidell Tax Care</h1>
          <h4 className="horarioss">Nuestros horarios</h4>
          <p>Lunes a Viernes de 9:00am hasta las 5:00pm</p>
          <p>Sabados desde las 10:00am hasta las 3:00pm </p>
          <p>
            Direccion :
            <a href="https://www.google.com/maps/place/SLIDELL+TAX+CARE+INC+%26+MURILLOS+INSURANCE+AGENCY/@30.2605079,-89.7761065,15.51z/data=!4m20!1m13!4m12!1m4!2m2!1d-89.7183536!2d30.2844982!4e1!1m6!1m2!1s0x889de7a6d4e07801:0xa308bdad574a74ca!2sslidell+tax+care!2m2!1d-89.7730006!2d30.2584315!3m5!1s0x889de7a6d4e07801:0xa308bdad574a74ca!8m2!3d30.2584315!4d-89.7730006!16s%2Fg%2F11k6zy2t1y?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D">
               3196 Terrace Avenue, Slidell, LA 70458
            </a>
          </p>{" "}
          <a href="tel:+19857071164">
            <i class="fa-solid fa-square-phone"></i>Telefono 985-707-1164
          </a>
        </div>
        <div>
          <a href="https://www.facebook.com/profile.php?id=100063736234301">
            <i className="fa-brands fa-facebook-square"></i>
          </a>
        </div>
      </div>

      <div className="bottom">
        {/* de este modo podemos poner algunas divisiones en el final de footer 
        <div>
          <h4>Project</h4>
          <a href="/">Uno</a>
          <a href="/">dos</a>
          <a href="/">tres</a>
          <a href="/">cuatro</a>
        </div>
        <div>
          <h4>Referencias</h4>
          <a href="/">Uno</a>
          <a href="/">dos</a>
          <a href="/">tres</a>
          <a href="/">cuatro</a>
        </div>
        <div>
          <h4>Virtudes</h4>
          <a href="/">Uno</a>
          <a href="/">dos</a>
          <a href="/">tres</a>
          <a href="/">cuatro</a>
        </div>
        <div>
          <h4>Diseno</h4>
          <a href="/">Uno</a>
          <a href="/">dos</a>
          <a href="/">tres</a>
          <a href="/">cuatro</a>
        </div>*/}
        <div>
          
          <h6>Design by @sirvaras 2024</h6>
          <h6>
            {" "}
            <a href="http://publixpc.com/">www.publixpc.com</a>
            
          </h6>
        </div>
      </div>
      
    </div>
  );
}

export default Footer;